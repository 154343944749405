import favicon16 from "../public/favicon-16x16.png";
import favicon32 from "../public/favicon-32x32.png";
import { getServerClient } from "./graphql";
import {
  MindstoneConfigDocument,
  MindstoneConfigQuery,
} from "./graphql/__generated";
import { ConfigContext, WebConfig } from "./hooks/webconfig";
import styles from "./tailwind.css?url";

import { data, LoaderFunctionArgs, redirect } from "react-router";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "react-router";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import IntercomInitializer from "~/components/intercom-initializer";
import {
  GenericCatchBoundary,
  GenericErrorBoundary,
} from "~/components/error-boundaries/error-boundaries";
import { rootGetAccessToken } from "~/utils/ensure-authenticated.server";
// import {
//   Notification,
//   NotificationProps,
// } from "./components/common/notification";
import {
  accessTokenCookie,
  csrfProtectionCookie,
  loggedInCookie,
  attributionCookie,
  getAttributionTrackingData,
} from "~/utils/oauth.server";
import { Toaster } from "@mindstonehq/ui";
import metrics from "~/utils/metrics";
import { ApolloHydrationHelper } from "@apollo/client-integration-react-router";

export function links() {
  return [
    { rel: "icon", type: "image/png", href: "/favicon.ico" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32 },
    { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16 },
    { rel: "stylesheet", href: styles },
    { rel: "stylesheet", href: "https://rsms.me/inter/inter.css" },
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
    },
  ];
}

export async function loader({ request }: LoaderFunctionArgs) {
  const configPromise = getServerClient().query<MindstoneConfigQuery>({
    query: MindstoneConfigDocument,
    fetchPolicy: "no-cache",
  });

  const configResult = await configPromise;
  if (configResult.error || !configResult.data) {
    console.error(configResult.error);
    throw "Something went wrong";
  }
  const config = configResult.data.config;
  const accessTokenResult = await rootGetAccessToken(request);
  let accessToken = "";
  let isNewAccessToken = false;
  if (accessTokenResult) {
    accessToken = accessTokenResult.accessToken;
    isNewAccessToken = accessTokenResult.new;
  }

  // Attribution tracking
  const attributionData = getAttributionTrackingData(request.url);
  const headers = new Headers();
  if (attributionData) {
    headers.append(
      "Set-Cookie",
      await attributionCookie.serialize(attributionData),
    );
  }
  if (isNewAccessToken) {
    headers.append("Set-Cookie", accessTokenCookie(accessToken));
    headers.append("Set-Cookie", loggedInCookie());
  }
  headers.append("Set-Cookie", csrfProtectionCookie(request));
  if (
    accessToken &&
    !request.url.includes("callback") &&
    !request.url.includes("onboarding")
  ) {
    // If we have a _callback_ in the URL path, we don't want to redirect
    //TODO: Redirect to default Mindspace URL when ready
    return redirect(config.appUrl, {
      headers,
      status: 302,
    });
  }

  return data(
    {
      config: {
        API: config.api,
        graphqlAPI: process.env.GRAPHQL_API,
        appUrl: config.appUrl, // TODO: This will become dynamic
        identityUrl: config.identityUrl,
        rudderstackURL: config.rudderstack?.url,
        rudderstackWriteKeyWebApp: config.rudderstack?.writeKey,
        revision: process.env.REVISION,
        oauthAppleRedirectUrl: config.oauth?.apple.replaceAll(
          "app.",
          "identity.",
        ),
        oauthGoogleRedirectUrl: config.oauth?.google.replaceAll(
          "app.",
          "identity.",
        ),
        oauthLinkedinRedirectUrl: config.oauth?.linkedIn.replaceAll(
          "app.",
          "identity.",
        ),
        intercomAppId: config.intercom,
        environment: config.environment,
        growthbookKey: config.growthbookKey,
        googleApiKey: config.googleMapKey,
      } as WebConfig,
    },
    {
      headers,
    },
  );
}
function App() {
  const { config: webConfig } = useLoaderData() as {
    config: WebConfig;
  };

  useEffect(() => {
    metrics.init(webConfig);
  }, [webConfig]);

  const [theme, setTheme] = useState(() => {
    return "light";
  });

  return (
    <html lang="en" className={theme}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
        <OurScripts webConfig={webConfig} />
      </head>
      <body className="min-h-[100dvh] bg-background text-foreground">
        <ConfigContext.Provider value={webConfig}>
          <ApolloHydrationHelper>
            <IntercomInitializer>
              <Outlet />
            </IntercomInitializer>
          </ApolloHydrationHelper>
        </ConfigContext.Provider>
        <ScrollRestoration />
        <Scripts />
        <Toaster />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  const [theme, setTheme] = useState("light");

  useEffect(() => {
    const item = localStorage.getItem("ms-theme");
    setTheme(item || "light");
  }, []);

  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <html lang="en" className={theme}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + 'a3cb6uaz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.Intercom('boot', {
              app_id: 'a3cb6uaz',
              custom_launcher_selector: '#ms-intercom-launcher' 
           });`,
          }}
        />
      </head>
      <body className="min-h-[100dvh] bg-theme-background-grey-primary text-theme-text-primary">
        {isRouteErrorResponse(error) ? (
          <GenericCatchBoundary error={error} />
        ) : (
          <GenericErrorBoundary
            error={error instanceof Error ? error : new Error("Unknown Error")}
          />
        )}
        <ScrollRestoration />
      </body>
    </html>
  );
}

export default App;

const Trackers = () => {
  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=2676983045894747&ev=PageView&noscript=1"
        />
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function Load3rdPartyScripts() {
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                      'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '2676983045894747');
              fbq('track', 'PageView');
            })()`,
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          _linkedin_partner_id = "3583161";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
       
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`,
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
          qp('init', 'd8eb09b79e1e4535a6dcf54d3c33e59d');
          qp('track', 'ViewContent');`,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://q.quora.com/_/ad/d8eb09b79e1e4535a6dcf54d3c33e59d/pixel?tag=ViewContent&noscript=1"
        />
      </noscript>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
                !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);`,
        }}
      />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
          },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
          a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
          twq('config','obo6d');`,
        }}
      />
    </>
  );
};

const OurScripts = ({ webConfig }: { webConfig: WebConfig }) => {
  return (
    <>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.2.146/pdf.min.js" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.2.146/pdf.worker.min.js" />
      <script
        type="text/javascript"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `window.webConfig = ${JSON.stringify(webConfig)}`,
        }}
      />
    </>
  );
};
