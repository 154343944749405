import React, { useEffect } from "react";
import { useUser } from "~/hooks/user";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useConfig } from "~/hooks/webconfig";

export default function IntercomInitializer({
  children,
}: React.PropsWithChildren<{}>) {
  const config = useConfig();
  return (
    <IntercomProvider appId={config.intercomAppId}>
      <IntercomInit>{children}</IntercomInit>
    </IntercomProvider>
  );
}

function IntercomInit({ children }: React.PropsWithChildren<{}>) {
  const user = useUser();
  const { boot, hide } = useIntercom();
  useEffect(() => {
    if (!user) {
      return;
    }
    // @ts-ignore
    if (!window.intercomSettings) window.intercomSettings = {};

    boot({
      hideDefaultLauncher: true,
      customLauncherSelector: "#ms-intercom-launcher",
    });
    hide();
  }, [user]);
  return <>{children}</>;
}
